import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/xOBqqYb9tnE">
    <SEO title="Overcoming Failures - Crossing Rivers" />
  </Layout>
)

export default SermonPost
